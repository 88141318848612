<!-- 美丽乡村详情页-->
<template>
  <div class="viliageDetail w-1400">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/xiangninghui' }"
          >乡宁游</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/villiagelist' }"
          >美丽乡村</el-breadcrumb-item
        >
        <el-breadcrumb-item>{{ pageData.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="face mt-20">
      <div class="face-pic">
        <div class="pic_left">
          <el-carousel
            trigger="click"
            height="400px"
            arrow="never"
            @change="imgChange"
          >
            <el-carousel-item
              v-for="item in pageData.photoUrls"
              :key="item + 'url'"
            >
              <el-image
                style="width: 100%; height: 100%"
                :src="item"
                fit="cover"
              ></el-image>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="pic_right">
          <div class="pic_right_item">
            <el-image style="width: 100%; height: 100%" fit="cover" :src="p1"></el-image>
          </div>
          <div class="pic_right_item">
            <el-image style="width: 100%; height: 100%" fit="cover" :src="p2"></el-image>
          </div>
        </div>
      </div>
      <div class="face-inco">
        <div class="face-price f-h4">{{pageData.name}}</div>
        <!-- <div class="face-intro">
          <ul class="f_row">
            <li class="fl">人口：{{pageData.population}}人</li>
            <li class="fl">户数：{{pageData.households}}户</li>
            <li class="fl">面积：{{pageData.landArea}}亩</li>
            <li class="fl">耕地：{{pageData.farmArea}}亩</li>
          </ul>
        </div> -->
        <div class="f-item">
          <div class="f-label">联系人：</div>
          <div class="f-value">{{pageData.contacts&&pageData.contacts.substr(0,1)}}**</div>
        </div> 
        <div class="f-item">
          <div class="f-label">预定电话：</div>
          <div class="f-value">{{pageData.telephone}}</div>
        </div> 
        <div class="f-item">
          <div class="f-label">乡村特色:</div>
          <div class="f-box">{{pageData.features}}</div>
        </div> 
        <div class="f-item">
          <div class="f-label">路线：</div>
          <div class="f-value">{{pageData.route}}</div>
        </div>
      </div>
    </div>
    <div class="intro-text mt-20">
      <h3><img src="../../../assets/img/hot-icon/jianjie.png" alt=""></h3>
      <div>
        {{ pageData.introduction }}
      </div>
    </div>
    <div class="others mt-20">
      <el-row :gutter="24">
        <el-col :span="16">
          <!--待租房屋-->
          <!-- <div class="house">
            <div class="others-title">
              <div class="fl h5">{{resourcePageData.title}}<b>待租农房</b></div>
              <router-link :to="`/resource`">
                <div class="fr more">
                  更多<i class="el-icon-arrow-right"></i>
                </div>
              </router-link>
            </div>
            <el-row :gutter="12">
              <el-col
                :span="8"
                v-for="itemv in resourcePageData"
                :key="itemv.id + 'house'"
              >
                <div class="house-item">
                  <div class="house-pic">
                    <router-link
                      :to="`/redirect?id=${itemv.id}&path=/propertydetail`"
                    >
                      <el-image
                        style="width: 100%; height: 100%"
                        :src="itemv.photoUrls.split(',')[0]"
                      ></el-image>
                    </router-link>
                  </div>
                  <div class="house-info">
                    <p class="house-h5">房屋户型：{{ itemv.houseLayout }}</p>
                    <p class="house-h5">房屋朝向：{{ itemv.orientation }}</p>
                    <p class="house-h5">装修情况{{ itemv.decoration }}</p>
                    <p class="house-price">类型：{{ itemv.houseType }}</p>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div> -->
          <!--交通路线-->
          <div class="house mt-20">
            <div class="others-title">
              <div class="fl h5">交通路线</div>
            </div>
            <div class="path">
              <p>自驾路线：无</p>
              <p>公交路线：无</p>
            </div>
          </div>
          <!--位置与配套-->
          <div class="house mt-20">
            <div class="others-title">
              <div class="fl h5">位置与配套</div>
            </div>
            <div class="position">
              <!-- <el-image style="width:100%;height:100%" :src="require('../../../../public/img/xnh/kayang-map.png')"></el-image>     -->
              <arc-map :title="pageData.name" :center="[pageData.longitude, pageData.latitude]"></arc-map>
              <!-- <baidu-map
                class="map"
                :center="{ lng: pageData.longitude, lat: pageData.latitude }"
                :scroll-wheel-zoom="true"
                :zoom="14"
              >
                <bm-marker
                  :position="{
                    lng: pageData.longitude,
                    lat: pageData.latitude,
                  }"
                  :dragging="true"
                >
                  <bm-label
                    :content="pageData.name"
                    :labelStyle="{
                      color: 'skyblue',
                      fontSize: '16px',
                      border: '1px solid skyblue',
                      transform: 'translateX(-48%)',
                    }"
                    :offset="{ width: 0, height: 30 }"
                  />
                </bm-marker>
              </baidu-map> -->
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="advertising">
            <div class="advertising-h3 clearfix">
              <div class="fl">附近热门村庄</div>
              <router-link to="/villiagelist">
                <div class="fr more">
                  更多<i class="el-icon-arrow-right"></i>
                </div>
              </router-link>
            </div>
            <div class="advertising-main">
              <div
                class="advertising-itme clearfix"
                v-for="item in VillagePageData"
                :key="item.id + 'near'"
              >
                <div class="advertising-itme-pic fl">
                  <router-link
                    :to="`/redirect?id=${item.id}&path=/villiagedetail`"
                  >
                    <el-image
                      style="width: 100%; height: 100%"
                      :src="item.photoUrls[0]"
                    ></el-image>
                  </router-link>
                </div>
                <div class="item-info fr">
                  <div class="name">名称：{{ item.name }}</div>
                  <div class="address">类型：{{ item.villageType }}</div>
                  <!-- <div class="buy">在租{{ item.households }}套</div> -->
                  <!-- <div class="market">
                    <span>湖泊</span>
                    <span>森林</span>
                    <span>耕地</span>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          <!-- 附近景点-->
          <div class="advertising mt-20">
            <div class="advertising-h3 clearfix">
              <div class="fl">附近热门景点</div>
              <router-link to="/scenerySpotList">
                <div class="fr more">
                  更多<i class="el-icon-arrow-right"></i>
                </div>
              </router-link>
            </div>
            <div class="advertising-main">
              <div
                class="advertising-itme clearfix"
                v-for="item in ScenerySpotPageData"
                :key="item.id + 'near'"
              >
                <div class="advertising-itme-pic fl">
                  <router-link :to="`/scenerySpotdetail?id=${item.id}`">
                    <el-image
                      style="width: 100%; height: 100%"
                      :src="item.photoUrls[0]"
                    ></el-image>
                  </router-link>
                </div>
                <div class="item-info fr">
                  <div class="name">名称：{{ item.name }}</div>
                  <div class="score">星级：{{ item.grade }}</div>
                  <div class="price buy">价格：{{ item.price }}元</div>
                  <!-- <div class="market2">
                    <span>游湖</span>
                    <span>棋牌</span>
                    <span>快艇</span>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          <!-- 附近住宿-->
          <div class="advertising mt-20">
            <div class="advertising-h3 clearfix">
              <div class="fl">附近热门住宿</div>
              <router-link to="/villiagelist">
                <div class="fr more">
                  更多<i class="el-icon-arrow-right"></i>
                </div>
              </router-link>
            </div>
            <div class="advertising-main">
              <div
                class="advertising-itme clearfix"
                v-for="item in SharedFarmPageData"
                :key="item.id + 'near'"
              >
                <div class="advertising-itme-pic fl">
                  <router-link :to="`/sharehousedetail?id=${item.id}`">
                    <el-image
                      style="width: 100%; height: 100%"
                      :src="item.photoUrls[0]"
                    ></el-image>
                  </router-link>
                </div>
                <div class="item-info fr">
                  <div class="name">名称：{{ item.name }}</div>
                  <div class="score">类型：{{ item.businessType }}</div>
                  <div class="price buy">价格：{{ item.pcConsumption }}元</div>
                  <!-- <div class="market2">
                    <span>游湖</span>
                    <span>棋牌</span>
                    <span>快艇</span>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import {
  GetVillage,
  GetVillagePage,
  GetScenerySpotPage,
  GetSharedFarmPage,
  GetHousePage,
} from "@/api/home.js";
import { mapState } from 'vuex';
export default {
  computed:{
    ...mapState({
      lonR:a=>a.lonR,
      latR:a=>a.latR,
    })
  },
  data() {
    return {
      pageData: {},
      params: {
        currentPage: 1,
        pageSize: 3,
        filter: {
          businessType: "精品民宿",
        },
      },
      SharedFarmPageData: {},
      ScenerySpotPageData: {},
      VillagePageData: {},
      currentPageData: {},
      resourceParams:{currentPage:1,pageSize:3},
      resourcePageData:[],
      p1:'',
      p2:'',
    };
  },
  methods: {
    imgChange(k){
      let next = k+1;
      if(!this.pageData.photoUrls[next]){
        next = 0;
      }
      this.p1 = this.pageData.photoUrls[next]
      this.p2 = this.pageData.photoUrls[next+1]?this.pageData.photoUrls[next+1]:this.pageData.photoUrls[0];
    },
    getPageData() {
      GetVillage(this.$route.query.id).then((res) => {
        if (res.data?.success == true) {
          const data = res.data.data;
          data.photoUrls = data.photoUrls.split(",");
          this.pageData = data;
          this.p1 = this.pageData.photoUrls[1]||'';
          this.p2 = this.pageData.photoUrls[2]||'';
          const newparams = {
            currentPage: 1,
            pageSize: 6,
            filter: { name: data.name },
          };
          this.getVillagePage(this.pageData);
          this.getScenerySpotPage(this.pageData);
          this.getSharedFarmPage(this.pageData);
          // GetVillagePage(newparams).then((ret) => {
          //   if (ret.data?.success) {
          //     const newdata = ret.data.data.list;
          //     newdata.forEach((d) => {
          //       d.photoUrls = d.photoUrls.split(",");
          //     });
          //     this.currentPageData = newdata;
          //   }
          // });
        }
      });
    },
    getSharedFarmPage(obj) {
      const params = {
        currentPage: 1,
        pageSize: 3,
        filter: {
          businessType: "精品民宿",
          longitude: obj.longitude,
          longitudeR: this.lonR,
          latitude: obj.latitude,
          latitudeR: this.latR
        },
      }
      GetSharedFarmPage(params).then((res) => {
        if (res.data?.success) {
          const data = res.data.data.list;
          data.forEach((d) => {
            d.photoUrls = d.photoUrls.split(",");
          });
          this.SharedFarmPageData = data;
        }
      });
    },
    getScenerySpotPage(obj) {
      const params = {
        currentPage: 1,
        pageSize: 3,
        filter: {
          longitude: obj.longitude,
          longitudeR: this.lonR,
          latitude: obj.latitude,
          latitudeR: this.latR
        },
      }
      GetScenerySpotPage(params).then((res) => {
        if (res.data?.success) {
          const data = res.data.data.list;
          data.forEach((d) => {
            d.photoUrls = d.photoUrls.split(",");
          });
          this.ScenerySpotPageData = data;
        }
      });
    },
    getVillagePage(obj) {
      const params = {
        currentPage: 1,
        pageSize: 3,
        filter: {
          longitude: obj.longitude,
          longitudeR: this.lonR,
          latitude: obj.latitude,
          latitudeR: this.latR
        },
      }
      GetVillagePage(params).then((res) => {
        if (res.data?.success) {
          const data = res.data.data.list;
          data.forEach((d) => {
            d.photoUrls = d.photoUrls.split(",");
          });
          this.VillagePageData = data;
        }
      });
    },
    getresourceHousePage(){
      GetHousePage(this.resourceParams).then(res=>{
        this.resourcePageData=res.data.data.list;
      })
    }
  },
  mounted() {
    this.getPageData();
    // this.getSharedFarmPage();
    // this.getScenerySpotPage();
    this.getresourceHousePage();
  },
};
</script>
<style scoped>
.f-box{
  line-height: 40px;
  padding: 0 20px;
  background: #367bff;
  font-size: 16px;
  color: #Fff;
  margin-left: 12px;
}
.f-item{
  display: flex;
  line-height: 40px;
}
.f_row{
  display: flex;
  justify-content: space-between;
}
.f-h4{
  font-size: 20px;
  font-weight: bold;
}
.map {
  height: 500px;
}
.position {
  padding: 12px;
  width: 100%;
  height: 600px;
  overflow: hidden;
}
.face-inco{
  width: 476px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 24px;
}
.path {
  padding-left: 30px;
}
.path p {
  height: 40px;
  line-height: 40px;
  color: #999;
  font-size: 16px;
}
.others .house-item {
  border: 1px solid #e5e5e5;
  margin-top: 15px;
}
.house-price {
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  color: #f9773b;
  text-align: right;
  padding-right: 12px;
}
.house-intro {
  font-size: 16px;
  line-height: 20px;
  text-indent: 24px;
  height: 60px;
  color: #777;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.house-h5 {
  font-size: 18px;
  font-weight: 600;
  height: 30px;
  line-height: 30px;
}
.house-info {
  padding: 10px;
}
.house-pic {
  width: 100%;
  height: 220px;
  overflow: hidden;
}
.others-title .more:hover {
  color: #1c91ff;
}
.others-title .h5 {
  font-size: 22px;
  color: #0169ca;
}
.others-title {
  height: 42px;
  line-height: 42px;
  border-bottom: 1px solid #e5e5e5;
}
.market2 span:nth-child(1) {
  border: 1px solid #f9773b;
  color: #f9773b;
}
.market2 span:nth-child(2) {
  border: 1px solid #1c91ff;
  color: #1c91ff;
}
.market2 span:nth-child(3) {
  border: 1px solid #33d448;
  color: #33d448;
}
.market2 span {
  padding: 0 8px;
  margin-right: 12px;
}
.score {
  color: #f9773b;
}
.market span {
  padding: 0 8px;
  background: #1c91ff;
  color: #fff;
  margin-left: 12px;
}
.item-info div.buy {
  color: #0169ca;
}
.item-info div {
  height: 29px;
  line-height: 29px;
  font-size: 16px;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.advertising-main {
  padding: 0 10px;
  border: 1px solid #e5e5e5;
}
.more:hover {
  color: #eee;
}
.more {
  cursor: pointer;
  font-size: 16px;
}
.advertising-h3 {
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  color: #fff;
  background: #0169ca;
  padding: 0 6px;
}
.advertising-itme {
  margin-top: 12px;
  overflow: hidden;
  width: 100%;
  height: 120px;
}
.advertising-itme-pic {
  width: 170px;
  height: 114px;
}
.item-info {
  width: calc(100% - 180px);
}
.intro-text div {
  font-size: 16px;
  text-indent: 20px;
  height: 120px;
  padding: 10px;
  margin-top: 10px;
  line-height: 30px;
  /* display: flex;
  align-items: center; */
  overflow-y: auto;
}
.intro-text h3 {
  height: 50px;
  background: #0169ca;
  width: 148px;
  text-align: center;
  padding-top: 9px;
  box-sizing: border-box;
}
.intro-text {
  /* border: 1px solid #666; */
  /* padding: 20px 10px; */
  border-bottom: 2px solid #e5e5e5;
}
.face-has li {
  background: #0169ca;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  margin-right: 30px;
}
.face-has ul {
  padding-left: 30px;
  width: 500px;
}
.face-has li {
  margin-top: 20px;
}
.face-intro,
.face-has {
  margin: 20px 0;
}
.face-intro li {
  border: 1px solid #5acaff;
  background: #b1e1fe;
  padding: 10px 12px;
  font-size: 16px;
}
.viliageDetail {
  font-family: "微软雅黑";
}
.f-label {
  font-size: 16px;
  line-height: 40px;
  color: #666;
  font-weight: bold;
  min-width: 48px;
  /* width: 80px; */
  /* text-align: right; */
}
.f-value{
  font-size: 16px;
  color: #141414;
}
.face-pic >>> .el-carousel__button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.face-pic {
  height: 400px;
  width: 700px;
  display: flex;
  justify-content: space-between;
}
.face{
  display: flex;
  justify-content: space-between;
  height: 400px;
  overflow: hidden;
}
.pic_left{
  width: 440px;
}
.pic_right{
  width: calc(100% - 460px);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.pic_right_item{
  height: 190px;
}
.breadcrumb >>> .el-breadcrumb .is-link,
.breadcrumb >>> .el-breadcrumb .el-breadcrumb__separator,
.breadcrumb >>> .el-breadcrumb .el-breadcrumb__inner {
  font-size: 18px;
  font-family: "微软雅黑";
  color: #0e6ac1;
}
.breadcrumb {
  padding: 20px 0 10px 0;
  border-bottom: 1px solid #0e6ac1;
  margin-bottom: 50px;
}
</style>
